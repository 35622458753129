import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from '../components/layout/layout';
import '../styles/contactPage.scss';

const ContactPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ContactPage {
      allContentfulGeneral {
        edges {
          node {
            contactPageEmail
            contactPageContent {
              raw
            }
          }
        }
      }
    }
  `)

  const generalData = data.allContentfulGeneral.edges[0].node

  return <Layout location={location}>
    <div className="ContactPage">
      <div className="ContactPage__content">
        <div className="ContactPage__title" />
        <div className="ContactPage__email">
          {generalData.contactPageEmail}
        </div>
        <div className="ContactPage__email-subtext">
          {renderRichText(generalData.contactPageContent)}
        </div>
      </div>
    </div>
  </Layout>
}

export default ContactPage